import { useState, MouseEvent } from 'react';
import { auth } from '../../config/firebase';
import { useLogout } from '../auth/useLogout';
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import routes from '../../config/routes';

const settings = ['Logout'];

const Navigation = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [user] = useState(auth.currentUser);

  const navigate = useNavigate();

  const { handleLogout } = useLogout();

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSettings = (setting: string) => {
    switch (setting) {
      case 'Logout':
        handleLogout();
        return;
    }
  };

  return (
    <AppBar position='sticky' sx={{ height: '71px' }}>
      <Container
        sx={{
          maxWidth: '100% !important',
          width: 'auto',
          px: '0px !important',
          mx: '16px',
        }}
      >
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              sx={{ p: 0 }}
              size='large'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {routes.map(({ isProtected, name, path }) => {
                if (!isProtected) {
                  return;
                }

                return (
                  <MenuItem key={name} onClick={() => navigate(path)}>
                    <Typography textAlign='center'>{name}</Typography>
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {routes.map(({ isProtected, name, path }) => {
              if (!isProtected) {
                return;
              }

              const currentPath =
                window.location.pathname === '/'
                  ? '/magazyn'
                  : window.location.pathname;
              const currentlySelected = currentPath.includes(
                name.toLowerCase()
              );

              return (
                <Button
                  key={name}
                  onClick={() => navigate(path)}
                  sx={{
                    my: 2,
                    color: 'white',
                    display: 'block',
                    textDecoration: currentlySelected ? 'underline' : 'initial',
                    fontWeight: currentlySelected ? '900' : 'initial',
                  }}
                >
                  {name}
                </Button>
              );
            })}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title='Open settings'>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {user?.photoURL ? (
                  <Avatar alt='User photo' src={user.photoURL} />
                ) : (
                  <AccountCircleIcon fontSize='large' />
                )}
              </IconButton>
            </Tooltip>

            <Menu
              sx={{ mt: '45px' }}
              id='menu-appbar'
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => handleSettings(setting)}>
                  <Typography textAlign='center'>{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navigation;
