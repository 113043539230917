import Center from '../components/utils/Center';
import withLayout from '../components/layout/withLayout';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Container from '@mui/material/Container';

import { auth, db } from '../config/firebase';
import { createRef, useEffect, useState } from 'react';
import {
  collection,
  getDocs,
  QuerySnapshot,
  DocumentData,
} from '@firebase/firestore';
import { Box, Button } from '@mui/material';
import { SpendingType } from './types';
import { EditItem } from '../components/spendings/EditItem';
import { AddItem } from '../components/spendings/AddItem';
import { isAdminUser } from './helpers';
import dayjs from 'dayjs';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import * as htmlToImage from 'html-to-image';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Typography } from '@mui/material';
import AddAPhotoSharpIcon from '@mui/icons-material/AddAPhotoSharp';
import { PayoutModal } from '../components/spendings/PayoutModal';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface Props {}

const colorArray = [
  '#E6B33340',
  '#99996640',
  '#80B30040',
  '#E6B3B340',
  '#6680B340',
  '#FF1A6640',
  '#E6331A40',
  '#33FFCC40',
  '#66994D40',
  '#B366CC40',
  '#B3330040',
  '#CC80CC40',
  '#66664D40',
  '#991AFF40',
  '#E666FF40',
  '#4DB3FF40',
  '#1AB39940',
  '#E666B340',
  '#33991A40',
  '#CC999940',
  '#B3B31A40',
  '#00E68040',
  '#4D806640',
  '#80998040',
  '#E6FF8040',
  '#1AFF3340',
  '#99993340',
  '#FF338040',
  '#CCCC0040',
  '#66E64D40',
  '#4D80CC40',
  '#9900B340',
  '#E64D6640',
  '#4DB38040',
  '#FF4D4D40',
  '#99E6E640',
  '#6666FF40',
];

const Spendings = ({}: Props) => {
  const [data, setData] = useState<SpendingType[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentSelected, setCurrentSelected] = useState<SpendingType>();
  const [multiCurrentSelected, setMultiCurrentSelected] = useState<
    SpendingType[]
  >([]);

  const [user] = useState(auth.currentUser);
  const editBlocked = !isAdminUser(user);
  const isStan = isAdminUser(user);

  const [showDeleted, setShowDeleted] = useState(false);

  const spendingsCollectionRef = collection(db, 'spendings');

  const editButtonLocked = Boolean(multiCurrentSelected.length);

  const [showPayoutModal, setShowPayoutModal] = useState(false);
  const [isAgregationOpen, setIsAggregationOpen] = useState<string[]>([]);
  const [firmData, setFirmData] = useState<QuerySnapshot<DocumentData>>();
  const [colorSet, setColorSet] = useState(false);
  const [colorsMatch, setColorsMatch] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (!firmData) {
      return;
    }

    handleAgregation(firmData);
  }, [isAgregationOpen, firmData]);

  const generateBrighterColor = () => {
    const hue = Math.floor(Math.random() * 360);
    const saturation = Math.floor(Math.random() * 15) + 60; // Adjust the saturation for brighter colors
    const lightness = Math.floor(Math.random() * 15) + 60;

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const handleAgregation = (d: QuerySnapshot<DocumentData>) => {
    const regularData = [] as SpendingType[];
    const tmp = {} as { [key: string]: number };

    const totalDarek = {} as { [key: string]: number };
    const totalStan = {} as { [key: string]: number };

    const agregatedData = d.docs.reduce<{ [key: string]: any }>(
      (result, item) => {
        const elem = item.data() as SpendingType;
        const eId = elem.agregateId;

        if (!eId || isAgregationOpen.includes(eId)) {
          regularData.push({
            ...elem,
            id: item.id,
          });

          return result;
        }

        if (tmp[eId]) {
          tmp[eId] += 1;
        } else {
          tmp[eId] = 1;
        }

        const amount = Number(elem.amount);
        if (!elem.removed) {
          if (!totalStan[eId]) {
            totalStan[eId] = 0;
          }

          if (!totalDarek[eId]) {
            totalDarek[eId] = 0;
          }

          if (elem.addedBy === 'Darek dla Stan') {
            totalDarek[eId] += amount;
          }

          if (elem.addedBy === 'Stan dla Darek') {
            totalStan[eId] += amount;
          }

          if (elem.addedBy === 'Stan / 2') {
            totalStan[eId] += amount;
            totalDarek[eId] += amount / 2;
          }

          if (elem.addedBy === 'Darek / 2') {
            totalStan[eId] += amount / 2;
            totalDarek[eId] += amount;
          }
        }

        if (result[eId]) {
          result[eId].amount = totalDarek[eId] - totalStan[eId];
          result[eId].elementName = `Rozliczone. Pozycja łączona z ${tmp[eId]}`;
          result[eId].createdAt = null;
          result[eId].addedBy = '-';
        } else {
          result[eId] = {
            agregateId: eId,
            ...elem,
            id: item.id,
            amount: totalDarek[eId] - totalStan[eId],
            elementName: `Rozliczone. Pozycja łączona z 1.`,
            createdAt: null,
            addedBy: '-',
          };
        }

        return result;
      },
      {}
    );

    if (!colorSet) {
      const colorMap = {} as { [key: string]: string };
      const idArray = Object.keys(agregatedData);

      idArray.forEach((string, id) => {
        if (!colorMap[string]) {
          // Generate a random color
          const color = colorArray[id]; //generateBrighterColor();
          colorMap[string] = color;
        }
      });

      setColorsMatch(colorMap);
      setColorSet(true);
    }

    const agregatedItems = Object.values(agregatedData) as SpendingType[];
    const joinedItems = [...agregatedItems, ...regularData];
    setData(joinedItems);
  };

  const getData = async () => {
    try {
      const d = await getDocs(spendingsCollectionRef);
      setFirmData(d);
    } catch (error) {
      //@ts-ignore
      toast.error(error.message);
      console.error(error);
    }
  };

  const editRow = (itemId: string) => {
    if (editButtonLocked) {
      return;
    }
    const selectedItem = data.find((item) => item.id === itemId);

    if (selectedItem) {
      setCurrentSelected(selectedItem);
      setEditModalOpen(true);
    }
  };

  const handleMulitSettlement = (item: SpendingType) => {
    const itemAdded = multiCurrentSelected.find((c) => c.id === item.id);

    if (itemAdded) {
      const updatedCurrentSelected = multiCurrentSelected.filter(
        (e) => e.id !== item.id
      );
      setMultiCurrentSelected(updatedCurrentSelected);
    } else {
      setMultiCurrentSelected((prev) => [...prev, item]);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  let totalStan = 0;
  let totalDarek = 0;

  const dateTimeValue = dayjs().format('DD-MM-YYYY-HH:mm');
  const [screenshotDisabled, setScreenshotDisabled] = useState(false);
  const storage = getStorage();
  const tableRef = createRef<HTMLElement | null>();
  const screenShotName = `wydatki_${dateTimeValue}`;
  const tableImageRef = ref(storage, `screenshots/wydatki/${screenShotName}`);

  const takeScreenShot = async (node: HTMLElement) => {
    const blob = await htmlToImage.toBlob(node);

    if (!blob) {
      setScreenshotDisabled(false);
      toast.error('Coś poszło nie tak!');
      return;
    }

    try {
      await uploadBytes(tableImageRef, blob, {
        contentType: 'image/jpeg',
        customMetadata: { filename: screenShotName },
      });
      toast.success('Screenshot został zapisany!');
    } catch (error) {
      toast.error('Coś poszło nie tak!');
    } finally {
      setScreenshotDisabled(false);
    }
  };

  return (
    <Container
      sx={{
        p: '0px !important',
        m: '24px',
        maxWidth: '100% !important',
        width: 'auto',
      }}
    >
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {!editBlocked ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: '20px',
              mr: 'auto',
              ml: '0',
              height: '55px',
              cursor: 'pointer',
            }}
          >
            <Button
              variant='contained'
              disabled={screenshotDisabled}
              onClick={() => {
                if (screenshotDisabled) {
                  return;
                }

                if (tableRef && tableRef.current) {
                  setScreenshotDisabled(true);
                  takeScreenShot(tableRef.current);
                }
              }}
            >
              <AddAPhotoSharpIcon />
            </Button>
          </Box>
        ) : (
          <Box></Box>
        )}
        {!editBlocked && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: '20px',
              mr: '16px',
              height: '50px',
            }}
          >
            <Button variant='contained' onClick={() => setModalOpen(true)}>
              Dodaj
            </Button>
          </Box>
        )}
        {data.length ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: '20px',
              mr: '16px',
              height: '50px',
            }}
          >
            <Button
              variant='contained'
              onClick={() => setShowDeleted((prev) => !prev)}
            >
              {!showDeleted ? 'Pokaż usunięte' : 'Schowaj usunięte'}
            </Button>
          </Box>
        ) : null}
      </Box>

      {!editBlocked ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: '20px',
            mr: '16px',
            height: '50px',
          }}
        >
          <Button
            variant='contained'
            disabled={!multiCurrentSelected.length}
            onClick={() => setShowPayoutModal(true)}
          >
            Rozlicz
          </Button>
        </Box>
      ) : null}

      <AddItem
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        getItems={getData}
      />

      <EditItem
        currentSelected={currentSelected}
        setEditModalOpen={setEditModalOpen}
        editModalOpen={editModalOpen}
        getItems={getData}
      />

      <PayoutModal
        multiCurrentSelected={multiCurrentSelected}
        setMultiCurrentSelected={setMultiCurrentSelected}
        setEditModalOpen={setShowPayoutModal}
        editModalOpen={showPayoutModal}
        getItems={getData}
      />

      <Center>
        <div
          style={{ width: '100%' }}
          //@ts-ignore
          ref={tableRef}
        >
          {data.length ? (
            <TableContainer
              component={Paper}
              sx={{ mt: '90px', overflowX: 'initial', position: 'relative' }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: '-50px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    visibility: screenshotDisabled ? 'visible' : 'hidden',
                  }}
                >
                  data i czas zrobienia screenshota: {dateTimeValue}
                </Typography>
              </Box>
              <Table
                sx={{
                  minWidth: 550,
                  '& .MuiTableCell-root': {
                    borderLeft: '1px solid rgba(224, 224, 224, 1)',
                  },
                }}
                stickyHeader
              >
                <TableHead
                  sx={{
                    transform: 'translateY(70px)',
                    zIndex: '1',
                    position: 'relative',
                  }}
                >
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      Nazwa wydatku
                    </TableCell>
                    <TableCell align='center' sx={{ fontWeight: 'bold' }}>
                      Kwota
                    </TableCell>
                    <TableCell align='center' sx={{ fontWeight: 'bold' }}>
                      Data dodania
                    </TableCell>
                    <TableCell align='center' sx={{ fontWeight: 'bold' }}>
                      Kto wydał
                    </TableCell>
                    <TableCell align='center' sx={{ fontWeight: 'bold' }}>
                      Szczegóły rozliczenia
                    </TableCell>
                    <TableCell align='center' sx={{ fontWeight: 'bold' }}>
                      Data rozliczenia
                    </TableCell>
                    {!editBlocked ? (
                      <TableCell align='right' sx={{ fontWeight: 'bold' }}>
                        Akcja
                      </TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ height: '70px' }}>
                    <TableCell />
                  </TableRow>
                  {data // @ts-ignore
                    .sort((a, b) => {
                      if (a.hasBeenUsed && !b.hasBeenUsed) {
                        return -1; // a comes before b
                      } else if (!a.hasBeenUsed && b.hasBeenUsed) {
                        return 1; // b comes before a
                      }

                      if (a.payoutDate || b.payoutDate) {
                        //@ts-ignore
                        return new Date(b.payoutDate) - new Date(a.payoutDate);
                      }
                      //@ts-ignore
                      return new Date(b.createdAt) - new Date(a.createdAt);
                    })
                    .map((d) => {
                      if (!showDeleted && d.removed) {
                        return;
                      }

                      if (showDeleted && !d.removed) {
                        return;
                      }

                      const itemSelectedFound = multiCurrentSelected.find(
                        (e) => e.id === d.id
                      );
                      const isSelected = Boolean(itemSelectedFound);

                      const removedCellStyles = d.removed
                        ? {
                            textDecoration: 'line-through',
                            fontWeight: 'bold',
                            color: 'red',
                          }
                        : {};

                      const amount = Number(d.amount);

                      if (!d.removed && !d.hasBeenUsed) {
                        if (d.addedBy === 'Darek dla Stan') {
                          totalDarek += amount;
                        }

                        if (d.addedBy === 'Stan dla Darek') {
                          totalStan += amount;
                        }

                        if (d.addedBy === 'Stan / 2') {
                          totalStan += amount;
                          totalDarek += amount / 2;
                        }

                        if (d.addedBy === 'Darek / 2') {
                          totalStan += amount / 2;
                          totalDarek += amount;
                        }
                      }

                      return (
                        <TableRow
                          key={d.id}
                          sx={{
                            background: isSelected
                              ? '#ffcdcd'
                              : d.agregateId
                              ? colorsMatch[d.agregateId]
                              : d.hasBeenUsed
                              ? '#f9f214'
                              : '#fff',
                            userSelect: 'none',
                          }}
                        >
                          <TableCell
                            component='th'
                            scope='row'
                            sx={removedCellStyles}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              minHeight: '30px',
                              cursor: d.agregateId ? 'pointer' : 'initial',
                              borderLeft:
                                d.agregateId &&
                                isAgregationOpen.includes(d.agregateId)
                                  ? `4px solid ${colorsMatch[
                                      d.agregateId
                                    ].slice(0, -2)}`
                                  : 'none !important',
                            }}
                            onClick={() => {
                              if (!d.agregateId) {
                                return;
                              }

                              const updatedData = isAgregationOpen.includes(
                                d.agregateId
                              )
                                ? isAgregationOpen.filter(
                                    (e) => e !== d.agregateId
                                  )
                                : [...isAgregationOpen, d.agregateId];
                              setIsAggregationOpen(updatedData);
                            }}
                          >
                            {d.elementName}
                            {d.agregateId ? (
                              isAgregationOpen.includes(d.agregateId) ? (
                                <ArrowDropUpIcon />
                              ) : (
                                <ArrowDropDownIcon />
                              )
                            ) : (
                              <Box />
                            )}
                          </TableCell>
                          <TableCell
                            component='th'
                            scope='row'
                            align='right'
                            sx={removedCellStyles}
                          >
                            {amount.toFixed(2)}zł
                          </TableCell>
                          <TableCell component='th' scope='row' align='right'>
                            {d.createdAt
                              ? dayjs(d.createdAt).format('DD/MM/YYYY')
                              : '-'}
                          </TableCell>
                          <TableCell component='th' scope='row' align='right'>
                            {d.addedBy}
                          </TableCell>
                          <TableCell component='th' scope='row' align='left'>
                            {d.details || '-'}
                          </TableCell>
                          <TableCell component='th' scope='row' align='right'>
                            {d.payoutDate
                              ? dayjs(d.payoutDate).format('DD/MM/YYYY')
                              : '-'}
                          </TableCell>

                          {!d.hasBeenUsed && !editBlocked && !d.removed ? (
                            <TableCell component='th' scope='row' align='right'>
                              <Button
                                size='small'
                                variant='contained'
                                type='submit'
                                disabled={editButtonLocked}
                                onClick={() => editRow(d.id)}
                              >
                                Edytuj
                              </Button>

                              <Button
                                size='small'
                                variant='contained'
                                type='button'
                                onClick={() => handleMulitSettlement(d)}
                                sx={{ ml: '20px' }}
                              >
                                +
                              </Button>
                            </TableCell>
                          ) : (
                            <TableCell />
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              <Box
                sx={{
                  paddingRight: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: '40px',
                  }}
                >
                  {isStan
                    ? totalStan - totalDarek > 0
                      ? 'do odebrania od Darka:'
                      : 'do oddania Darkowi:'
                    : totalDarek - totalStan > 0
                    ? 'do odebrania od Staszka:'
                    : 'do oddania Staszkowi:'}
                  <Box
                    sx={{
                      fontWeight: 'bold',
                      marginLeft: '10px',
                      minWidth: '150px',
                      textAlign: 'end',
                    }}
                  >
                    {isStan
                      ? Math.abs(totalStan - totalDarek).toFixed(2)
                      : Math.abs(totalDarek - totalStan).toFixed(2)}
                    zł
                  </Box>
                </Box>
              </Box>
            </TableContainer>
          ) : (
            <Box sx={{ my: '20px', textAlign: 'center' }}>Brak danych</Box>
          )}
        </div>
      </Center>
    </Container>
  );
};

export default withLayout(Spendings);
